import { ENV } from './env.js';

// export const BASE_URL = "https://us-west2-insightlist-dev.cloudfunctions.net";
const API_BASE_URL = ENV.API_BASE_URL;

export const USERS_URL = API_BASE_URL + "/users";

export const BASE_HEADERS = {
  'Content-Type': 'application/json',
  // 'Authorization': 'Bearer ' + id_token,
  // 'Access-Control-Request-Headers': 'Content-Type, Authorization',
  // 'Access-Control-Request-Headers': 'Authorization, Content-Type',
  // 'Origin': 'https://insightlist-dev.web.app',
  // 'Access-Control-Allow-Origin': ENV.ORIGIN_URL
  'Access-Control-Allow-Origin': ENV['ORIGIN_URL']
}

export function addAuthHeader(headers, idToken) {
  headers.Authorization = 'Bearer ' + idToken;
  return headers
}

export function addAuthRequestParams(requestBody, idToken) {
  requestBody.id_token = idToken;
  return requestBody
}

export function getUser(idToken) {
  var headers = BASE_HEADERS;
  headers = addAuthHeader(headers, idToken);
  var requestParams = addAuthRequestParams({}, idToken);

  return fetch(USERS_URL + '?' + new URLSearchParams(requestParams), {
    method: "GET",
    headers: headers,
  }).then(response => response.json()).then(user => {
    if (!!user) {
      if (!user.favorite_stock_ids) {
        user.favorite_stock_ids = [];
      }
      if (!user.favorite_stocks) {
        user.favorite_stocks = [];
      }
      if (!user.delivery_days_of_week) {
        user.delivery_days_of_week = [];
      }
    }
    return user;
  });
}

export function updateUser(user, idToken) {
  var headers = BASE_HEADERS;
  headers = addAuthHeader(headers, idToken);
  // headers['Access-Control-Request-Method'] = 'PUT';
  var requestParams = addAuthRequestParams({}, idToken);

  // return fetch(USERS_URL, {
  return fetch(USERS_URL + '?' + new URLSearchParams(requestParams), {
    method: "PUT",
    headers: headers,
    body: JSON.stringify({
      id_token: idToken,
      favorite_stock_ids: user.favorite_stock_ids,
      delivery_days_of_week: user.delivery_days_of_week
      // email: user.email
    })
  })
}


export function getStocks(idToken, limit=100) {
  var headers = BASE_HEADERS;
  headers = addAuthHeader(headers);
  var requestParams = addAuthRequestParams({}, idToken);
  requestParams['limit'] = limit;

  return fetch(
    API_BASE_URL + '/get_stocks?' + new URLSearchParams(requestParams),
    {
      method: 'GET',
      headers: headers
    }
  ).then(response => response.json()).then(res_json => {
    // if (!!stocks) {
      // if (!user.favorite_stock_ids) {
      //   user.favorite_stock_ids = [];
      // }
      // if (!user.favorite_stocks) {
      //   user.favorite_stocks = [];
      // }
      // if (!user.delivery_days_of_week) {
      //   user.delivery_days_of_week = [];
      // }
    // }
    return res_json.stocks;
  });
}
