import {XIcon} from "@heroicons/react/outline";
import {updateUser} from "./service";
import React, { useState} from 'react';
import SelectBox from './SelectBox';

export class SelectedStock extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.stock = props.stock;
    this.state = {
      user: props.user,
      idToken: props.idToken
    }
  }

  updateState(user, idToken) {
    this.setState({user: user, idToken: idToken});
    // this.forceUpdate();
    this.render();
  }

  removeStock() {
    const stockName = this.stock.symbol;
    // const stockName = this.stock.symbol;
    this.state.user.favorite_stocks = this.state.user.favorite_stocks.filter(function(val, index, arr) {
      return val.symbol !== stockName;
    });
    this.state.user.favorite_stock_ids = this.state.user.favorite_stocks.map(stock => stock.symbol);
    this.setState({user: this.state.user});
    this.updateState(this.state.user, this.state.idToken);
    updateUser(this.state.user, this.state.idToken);
    // 親コンポーネントの再描画 -> 自コンポーネントの除去
    this.props.updateUserState(this.state.user);
  }

  render() {
    return <li
      key={this.stock.symbol}
      className="py-4 flex items-center justify-between space-x-3"
    >
      <div className="min-w-0 flex-1 flex items-center space-x-3">
        <div className="flex-shrink-0">
          <img
            className="h-10 w-10 rounded-full"
            src={this.stock.logo}
            alt=""
          />
        </div>
        <div className="min-w-0 flex-1">
          <p className="text-sm font-medium text-gray-900 truncate">
            {this.stock.name}
          </p>
          <p className="text-sm font-medium text-gray-500 truncate">
            {this.stock.symbol}
          </p>
        </div>
      </div>
      <div className="flex-shrink-0">
        <button
          type="button"
          // id="remove-stock-"{stock.name}
          onClick={(e) => this.removeStock()}
          className="inline-flex items-center py-2 px-3 border border-transparent rounded-full bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <XIcon
            className="mr-1 h-4 w-4 text-gray-400"
            aria-hidden="true"
          />
          <span className="text-sm font-medium text-gray-800">
            Remove{" "}
            <span className="sr-only">
              {this.stock.name}
            </span>{" "}
          </span>
        </button>
      </div>
    </li>;
  }
}


// export default class StockPreferences extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       user: props.user,
//       idToken: props.idToken
//     }
//   }
//
//   updateUserState(user) {
//     this.setState({user: user});
//     this.render();
//   }
//
//   render() {
//     return <section aria-labelledby="stocks">
//       <form action="#" method="POST">
//         <div className="shadow sm:rounded-md sm:overflow-hidden">
//           <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
//             <div>
//               <h2 className="mb-1 text-lg leading-6 font-medium text-gray-900">
//                 Stocks
//               </h2>
//               <div className="mb-6">
//                 <p className="text-sm text-gray-500">
//                   Add any favorite stock to your preference, which
//                   will enhance the recommendation quality.
//                 </p>
//               </div>
//               <SelectBox />
//             </div>
//
//             <div className="mt-10 p-6 rounded-md bg-gray-50">
//               <h3 className="text-xs font-semibold text-gray-500 uppercase tracking-wide mb-5">
//                 Stocks already added to your preference
//               </h3>
//
//               <ul id="selected-stocks" className="border-t border-b  border-gray-200 divide-y divide-gray-200">
//                 {this.state.user.favorite_stocks.map((stock, stockIdx) => (
//                   <SelectedStock stock={stock} user={this.state.user} idToken={this.state.idToken} updateUserState={() => { this.updateUserState(this.state.user); }}/>
//                 ))}
//               </ul>
//
//             </div>
//           </div>
//         </div>
//       </form>
//     </section>
//   }
// }
