import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { App } from "./App";
import { LP } from "./LP";

const rootElement = document.getElementById("root");
ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route exact path="/" element={<LP />} />
      <Route path="/account" element={<App />} />
    </Routes>
  </BrowserRouter>,
  rootElement
);
